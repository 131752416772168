import React from "react";
import OpeningHours from "../../components/forms/OpeningHour";
import Courts from "../../components/forms/Courts";
import HallInfos from "../../components/forms/HallInfos";
import Sponsors from "../../components/upload/Sponsors";

const Hall = () => {
  return (
    <>
      <HallInfos />
      <Courts />
      <OpeningHours />
      <Sponsors />
    </>
  );
}

export default Hall;