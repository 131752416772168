import React from "react";
import BookingList from "../../components/lists/BookingList";
import BookingOverviewFilter from "../../components/filters/BookingOverview";
import FiveDayCalender from "../../components/calender/FiveDayCalender";

const Calender = () => {
    return (
        <div className="flex flex-col w-full overflow-hidden">
            <FiveDayCalender />
        </div>
    );
}

export default Calender;