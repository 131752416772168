import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';
import ShowForm from "../forms/ShowForm";

const CoachList = () => {
    const [selectedTrainerId, setselectedTrainerId] = useState(null);
    const [selectUser, setSelectUser] = useState(null);
    const [trainers, setTrainers] = useState([]);

    useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get('/trainer', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setTrainers(response.data);  
            } catch (error) {
                console.error('Fehler beim Abrufen der Trainer:', error);
            }
        };

        fetchTrainers();
    }, []);

    const handleRowClick = (coachId) => {
        setselectedTrainerId(coachId);
    };

    const closeForm = () => {
        setselectedTrainerId(null);
    };

    return (
        <div className="overflow-y-scroll w-[100%] h-[100%]">
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th className="w-[120px]" style={styles.th}></th>
                        <th style={styles.th}>Profil</th>
                        <th style={styles.th}>Status</th>
                        <th style={styles.th}>Aktion</th>
                    </tr>
                </thead>
                <tbody>
                {trainers.length > 0 ? (
                    trainers.map((coach) => (
                        <tr key={coach._id}>
                            <td className="flex flex-col gap-[8px] w-[120px]" style={styles.td}>
                                <button className="p-[8px] text-[12px] border rounded-[8px]">Details</button>
                            </td>
                            <td style={styles.td} className="relative">
                                <div>{coach.name}</div>
                                <div>{coach.email}</div>
                                {selectUser === coach._id && (
                                    <div className="absolute bottom-0 bg-white p-[8px] border rounded-[8px] min-w-[200px]">
                                        <h5>Profil</h5>
                                        <p>{coach.name}</p>
                                    </div>
                                )}
                            </td>
                            <td style={styles.td}>
                                <div className={coach.status}></div>
                                {coach.status}
                            </td>
                            <td style={styles.td}>
                                <button onClick={() => handleRowClick(coach._id)}>Details</button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" style={styles.td}>Keine Trainer gefunden.</td>
                    </tr>
                )}
                </tbody>
            </table>

            {selectedTrainerId && <ShowForm type="TrainerDetails" id={selectedTrainerId} onClose={closeForm} />}
        </div>
    );
}

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  },
  th: {
    backgroundColor: '#f2f2f2',
    padding: '12px',
    border: '1px solid #ddd',
    position: 'sticky',
    top: 0,
    left: 0,
  },
  td: {
    padding: '12px',
    border: '1px solid #ddd',
    minHeight: '60px',
  },
};

export default CoachList;