import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const CourtDetails = ({ id, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        imageUrl: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [courtData, setCourtData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            setBaseUrl('http://localhost:8011');
        } else {
            setBaseUrl('https://next.agiplus.de/api/v1');
        }
    }, []);

    useEffect(() => {
        const fetchCourtDetails = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get(`/genx/hall/sponsors/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = response.data;
                setFormData({
                    name: data.name,
                    url: data.url,
                    imageUrl: data.imageUrl || ''
                });
                setImagePreviewUrl(data.imageUrl || '');
                setCourtData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching court details:', error);
                setError('Fehler beim Laden der Felddaten.');
                setLoading(false);
            }
        };

        fetchCourtDetails();
    }, [id]);

    const handleSave = async () => {
        try {
            await axios.put(`/sponsor/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Buchung:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/sponsor/${id}`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            onClose();
        } catch (error) {
            console.error('Fehler beim Löschen der Buchung:', error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setFormData({ ...formData, imageUrl: URL.createObjectURL(file) });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, url, imageUrl } = formData;
        const courtData = {
            name,
            url,
            imageUrl
        };

        try {
            const response = await axios.put(`/genx/hall/court/${id}`, courtData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            console.log('Court updated successfully:', response.data);
            onClose();
        } catch (error) {
            console.error('Error updating court:', error);
            setError('Fehler beim Aktualisieren des Feldes.');
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
            <h2>Sponsor</h2>
            {error && <p className="error-message mb-2 text-[12px] text-red-500">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    {isEditing ? (
                        <input
                            type="text"
                            name="name"
                            className='border rounded-[8px] p-[8px]'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    ) : (
                        <p>{formData.name}</p>
                    )}
                </div>
                <div>
                    <label>Url:</label>
                    <input
                        type="text"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    {!imagePreviewUrl ? (
                        <>
                            <label>Image:</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </>
                    ) : (
                        <div className="relative flex items-center justify-center h-[140px] w-[140px] border border-gray-300 mt-2">
                            <img
                                src={`${baseUrl}${imagePreviewUrl}`}
                                alt="Preview"
                                className="object-cover w-full h-full"
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    setImagePreviewUrl('');
                                    setFormData({ ...formData, imageUrl: '' });
                                }}
                                className="absolute top-2 right-2 bg-white flex w-[20px] h-[20px] text-[12px] border border-gray-400 p-1 rounded-full text-gray-900"
                            >
                                <p className='m-auto'>X</p>
                            </button>
                        </div>
                    )}
                </div>
                <div className='flex w-full justify-between'>
                    {isEditing ? (
                        <>
                            <button onClick={handleSave}>Speichern</button>
                            <button onClick={handleCancelClick}>Abbrechen</button>
                        </>
                    ) : (
                        <button onClick={handleEditClick}>Bearbeiten</button>
                    )}
                    <button onClick={handleDelete}>Löschen</button>
                </div>
            </form>
        </div>
    );
};

export default CourtDetails;