import React from "react";

const Contact = () => {
  return (
    <>
      <h1>Kontakt</h1>
    </>
  );
}

export default Contact;