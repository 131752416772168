import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import ShowForm from './ShowForm';

const Courts = () => {
    const [courts, setCourts] = useState([]);
    const [selectedCourtId, setSelectedCourtId] = useState(null);
    const [formType, setFormType] = useState(null);
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            setBaseUrl('http://localhost:8011');
        } else {
            setBaseUrl('https://next.agiplus.de/api/v1');
        }
    }, []);

    useEffect(() => {
        if (baseUrl) {
            const fetchCourts = async () => {
                try {
                    const token = Cookies.get('token');
                    const response = await axios.get('/genx/hall/court', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setCourts(response.data);
                } catch (error) {
                    console.error('Fetch halls failed:', error);
                }
            };
            fetchCourts();
        }
    }, [baseUrl]);

    const handleFieldSelect = (fieldId) => {
        setSelectedCourtId(fieldId);
        setFormType("CourtDetails");
    };

    const handleFieldCreate = () => {
        setSelectedCourtId(null);
        setFormType("CreateCourt");
    };

    const closeForm = () => {
        setSelectedCourtId(null);
        setFormType(null);
    };
    
    return (
        <>
            <div className='mb-12'>
                <div className="flex justify-between">
                    <div className="flex flex-col">
                        <h2 className='text-[18px] font-semibold mb-2'>Felder</h2>
                        <p>Stelle deine Felder ein.</p>
                    </div>
                    <button className="p-[8px] border rounded-[6px]" onClick={handleFieldCreate}>Neues Feld</button>
                </div>
                {courts.length > 0 ? (
                    <div className="my-[16px] flex gap-[8px]">
                        {courts.map((court, index) => (
                            <div 
                                className="flex w-[120px] h-[120px] border rounded-[8px] justify-center cursor-pointer bg-cover bg-center relative" 
                                key={court._id}
                                onClick={() => handleFieldSelect(court._id)}
                                style={{ backgroundImage: `url(${baseUrl}${court.imageUrl})` }}
                            >
                                <div className='w-100 h-100 bg-black opacity-50 absolute top-0 left-0 z-2'></div>
                                <div className="flex flex-col w-fit h-fit m-auto z-3">
                                    <h3 className={`font-semibold ${court.imageUrl ? 'text-white' : 'text-black'}`}>Feld {index + 1}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="my-[32px]">
                        <p>Keine Felder verfügbar</p>
                    </div>
                )}
            </div>
            {(formType && (
                <ShowForm 
                    type={formType} 
                    id={selectedCourtId} 
                    onClose={closeForm}
                />
            ))}
        </>
    );
};

export default Courts;