import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CoachList from "../../components/lists/CoachList";
import './Trainer.css';
import CoachesOverview from "../../components/filters/CoachesOverview";

const Overview = () => {
    const [view, setView] = useState("");
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const queryView = params.get("view");
        
        if (queryView) {
            setView(queryView);
        }
    }, [location]);

    return (
        <div className="flex flex-col w-full overflow-hidden">
            {view === "coaches" && (
                <>
                    <CoachesOverview />
                    <CoachList />
                </>
            )}
        </div>
    );
}

export default Overview;