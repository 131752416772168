import React from 'react';
import './Content.css';

import { Route, Routes } from 'react-router-dom';
import Overview from './home/Overview';
import Website from './home/Website';
import Hall from './home/Hall';
import Contact from './home/Contact';

const Home = () => {
  return (
    <>
      <Routes>
        <Route path="/overview" element={<Overview />} />
        <Route path="/hall" element={<Hall />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/website" element={<Website />} />
      </Routes>
    </>
  );
};

export default Home;