import React, { createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../pages/Loading';
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: '',
    userId: '',
    name: '',
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const setUserContext = (email, userId, name) => {
    setUser({ email, userId, name });
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser({ email: '', userId: '', name: '' });
    navigate('/login');
  };

  console.log('User:', user);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = Cookies.get('token');
      if (token) {
        try {
          const response = await axios.get('/user', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const { email, userId, name } = response.data;
          setUser({ email, userId, name });
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout();
        }
      } else {
        setUser({ email: '', userId: '', name: '' });
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{ user, setUserContext, logout}}>
      {children}
    </UserContext.Provider>
  );
};