import React from "react";
import BookingList from "../../components/lists/BookingList";
import BookingOverviewFilter from "../../components/filters/BookingOverview";
import './Overview.css';

const Overview = () => {
    return (
        <div className="flex flex-col w-full overflow-hidden">
            <BookingOverviewFilter />
            <BookingList />
        </div>
    );
}

export default Overview;