import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const TrainerDetails = ({ id, onClose }) => {
    const [coachDetails, setcoachDetails] = useState(null);
    const [coachSettings, setcoachSettings] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    
    const defaultSettings = {
        price: '50€',
        accept: 'yes'
    };

    useEffect(() => {
        const fetchCoachDetails = async () => {
            try {
                const token = Cookies.get('token');
    
                const response = await axios.get(`/genx/trainer/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const token = Cookies.get('token');
                    const responseSettings = await axios.get(`/genx/settings/trainer/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (responseSettings.data !== null){
                        setcoachSettings(responseSettings.data);
                    } else {
                        const token = Cookies.get('token');
                        const postResponse = await axios.post(`/genx/settings/trainer/${id}`, defaultSettings, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        });
                        if (postResponse.status === 201) {
                            setcoachSettings(defaultSettings);
                        }
                    }
                }

                setcoachDetails(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Trainer- oder Einstellungen:', error);
            }
        };
    
        fetchCoachDetails();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setcoachDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleDelete = async () => {
        try {
            const token = Cookies.get('token');
            await axios.delete(`/genx/trainer/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            onClose();
        } catch (error) {
            console.error('Fehler beim Löschen der Buchung:', error);
        }
    };

    const handleSave = async () => {
        
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    if (!coachDetails) {
        return (
            <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
                <div className='flex w-full justify-between'>
                    <h2>Details des Trainers</h2>
                    <button onClick={onClose}>Schließen</button>
                </div>
                <p>Lade Trainerdetails...</p>
            </div>
        );
    }

    return (
        <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
            <div className='flex w-full justify-between'>
                <h2>Details des Trainers</h2>
                <button onClick={onClose}>Schließen</button>
            </div>
            <div className='flex flex-col gap-[8px]'>
                <label>
                    <strong>Name:</strong>
                    <span>{coachDetails.name}</span>
                </label>
                <label>
                    <strong>Email:</strong>
                    <span>{coachDetails.email}</span>
                </label>
            </div>
            <div>
                <label>
                    <strong>Preis:</strong>
                    {isEditing ? (
                        <input 
                            type="text" 
                            name="price"
                            className='border rounded-[8px] p-[8px]' 
                            value={coachSettings.price} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{coachSettings.price}</span>
                    )}
                </label>
                <label>
                    <strong>Muss bestätigt werden:</strong>
                    {isEditing ? (
                        <div>
                            <label>
                                <input 
                                    type="radio" 
                                    name="accept" 
                                    value="ja" 
                                    checked={coachSettings.accept === 'ja'} 
                                    onChange={handleChange} 
                                    className='mr-[8px]'
                                />
                                Ja
                            </label>
                            <label className='ml-[16px]'>
                                <input 
                                    type="radio" 
                                    name="accept" 
                                    value="nein" 
                                    checked={coachSettings.accept === 'nein'} 
                                    onChange={handleChange} 
                                    className='mr-[8px]'
                                />
                                Nein
                            </label>
                        </div>
                    ) : (
                        <span>{coachSettings.accept === "yes" ? "Ja" : "Nein"}</span>
                    )}
                </label>
            </div>
            <div className='flex w-full justify-between'>
                {isEditing ? (
                    <>
                        <button onClick={handleSave}>Speichern</button>
                        <button onClick={handleCancelClick}>Abbrechen</button>
                    </>
                ) : (
                    <button onClick={handleEditClick}>Einstellungen</button>
                )}
                <button onClick={handleDelete}>Löschen</button>
            </div>
        </div>
    );
}

export default TrainerDetails;