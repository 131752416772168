import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import ShowForm from '../forms/ShowForm';

const Sponsors = () => {
    const [sponsors, setSponsors] = useState([]);
    const [selectedSponsorId, setSelectedSponsorId] = useState(null);
    const [formType, setFormType] = useState(null);
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            setBaseUrl('http://localhost:8011');
        } else {
            setBaseUrl('https://next.agiplus.de/api/v1');
        }
    }, []);

    useEffect(() => {
        if (baseUrl) {
            const fetchSponsors = async () => {
                try {
                    const token = Cookies.get('token');
                    const response = await axios.get(`/genx/hall/sponsor`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setSponsors(response.data);
                } catch (error) {
                    console.error('Fetch sponsors failed:', error);
                }
            };

            fetchSponsors();
        }
    }, [baseUrl]);

    const handleFieldSelect = (fieldId) => {
        setSelectedSponsorId(fieldId);
        setFormType("SponsorDetails");
    };

    const handleFieldCreate = () => {
        setSelectedSponsorId(null);
        setFormType("CreateSponsor");
    };

    const closeForm = () => {
        setSelectedSponsorId(null);
        setFormType(null);
    };

    if (!baseUrl) {
        return <div>Laden...</div>;
    }

    return (
        <>
            <div>
                <div className="flex justify-between">
                    <div className="flex flex-col">
                        <h2 className='text-[18px] font-semibold mb-2'>Sponsoren</h2>
                        <p>Hier kannst du die Sponsoren einrichten.</p>
                    </div>
                    <button className="p-[8px] border rounded-[6px]" onClick={handleFieldCreate}>Neuer Sponsor</button>
                </div>
                {sponsors.length > 0 ? (
                    <div className="my-[16px] flex gap-[8px]">
                        {sponsors.map((sponsor) => (
                            <div 
                                className="flex w-[120px] h-[120px] border rounded-[8px] justify-center cursor-pointer bg-cover bg-center relative" 
                                key={sponsor._id}
                                onClick={() => handleFieldSelect(sponsor._id)}
                                style={{ backgroundImage: `url("${baseUrl}${sponsor.imageUrl}")` }}
                            >
                                <div className='w-100 h-100 bg-black opacity-50 absolute top-0 left-0 z-2'></div>
                                <div className="flex flex-col w-fit h-fit m-auto z-3">
                                    <h3 className={`font-semibold ${sponsor.imageUrl ? 'text-white' : 'text-black'}`}>{sponsor.name}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="my-[32px]">
                        <p>Keine Sponsoren verfügbar</p>
                    </div>
                )}
            </div>
            {(formType && (
                <ShowForm 
                    type={formType} 
                    id={selectedSponsorId} 
                    onClose={closeForm} 
                />
            ))}
        </>
    );
};

export default Sponsors;