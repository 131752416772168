import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './pages/Dashboard';
import Cookies from 'js-cookie';

import { UserProvider } from './context/UserContext';

if (window.location.hostname === 'localhost') {
  axios.defaults.baseURL = 'http://localhost:8011/api/v1/';
} else {
  axios.defaults.baseURL = 'https://next.agiplus.de/api/v1/';
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('token'));
  const location = useLocation();
  const baseURL = window.location.hostname === 'localhost' ? 'http://localhost:8020' : 'https://user.agiplus.de';

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(!!Cookies.get('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const match = location.pathname.match(/^\/invite\/(.+)/);
    if (match) {
      const inviteCode = match[1];
      window.location.href = `${baseURL}/login?invite=${encodeURIComponent(inviteCode)}`;
    }
  }, [location, baseURL]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <UserProvider>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/*" element={<Dashboard onLogout={handleLogout} />} />
            <Route path="*" element={<Navigate to="/home/overview" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </UserProvider>
    
  );
};

export default App;