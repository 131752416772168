import React from 'react';
import '../forms/Form.css';
import BookingDetails from './BookingDetails';
import TrainerDetails from './TrainerDetails';
import BookingForm from '../BookingForm';
import ShareLink from './ShareLink';
import CourtDetails from './CourtDetails';
import CourtCreate from './CourtCreate';
import SponsorDetails from './SponsorDetails';
import SponsorCreate from './SponsorCreate';

const ShowForm = ({ type, id, onClose, }) => {
    const renderContent = () => {
        switch (type) {
            case "CourtDetails":
                return <CourtDetails id={id} onClose={onClose} />;
            case "CreateCourt":
                return <CourtCreate id={id} onClose={onClose} />;
            case "SponsorDetails":
                return <SponsorDetails id={id} onClose={onClose} />;
            case "CreateSponsor":
                return <SponsorCreate id={id} onClose={onClose} />;
            case "BookingDetails":
                return <BookingDetails id={id} onClose={onClose} />;
            case "TrainerDetails":
                return <TrainerDetails id={id} onClose={onClose} />;
            case "CreateBooking":
                return <BookingForm onClose={onClose} />;
            case "ShareLink":
                return <ShareLink id={id} onClose={onClose} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className='shadow' onClick={onClose}></div>
            {renderContent()}
        </>
    );
}

export default ShowForm;