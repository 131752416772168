import React, { useRef, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const SponsorCreate = ({ onClose }) => {
    const [sponsname, setSponsName] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            img.onload = () => {
                if (img.width < 100 || img.height < 100) {
                    setError('Das Bild muss mindestens 100px x 100px groß sein.');
                    setImage(null);
                    setImagePreviewUrl('');
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
                else if(img.width > 1000 || img.height > 1000) {
                    setError('Das Bild darf maximal 1000px x 1000px groß sein.');
                    setImage(null);
                    setImagePreviewUrl('');
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
                else {
                    const squareSize = Math.min(img.width, img.height);
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    canvas.width = squareSize;
                    canvas.height = squareSize;

                    ctx.drawImage(
                        img,
                        (img.width - squareSize) / 2,
                        (img.height - squareSize) / 2,
                        squareSize,
                        squareSize,
                        0,
                        0,
                        squareSize,
                        squareSize
                    );

                    canvas.toBlob((blob) => {
                        if (blob) {
                            setImage(new File([blob], file.name, { type: file.type }));
                            setImagePreviewUrl(URL.createObjectURL(blob));
                            setError('');
                        }
                    }, file.type);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const isValidUrl = (url) => {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + 
            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + 
            '((\\d{1,3}\\.){3}\\d{1,3}))' + 
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + 
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + 
            '(\\#[-a-zA-Z\\d_]*)?$', 'i'
        );
        return !!urlPattern.test(url);
    };

    const handleImageRemove = () => {
        setImage(null);
        setImagePreviewUrl('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError('');
    
        if (!sponsname || !url || !image) {
            setError('Bitte alle Pflichtfelder ausfüllen.');
            setIsSubmitting(false);
            return;
        }

        if (!isValidUrl(url)) {
            setError('Bitte eine gültige URL eingeben.');
            setIsSubmitting(false);
            return;
        }
    
        try {
            const token = Cookies.get('token');
            const formData = new FormData();
            formData.append('name', sponsname);
            formData.append('url', url);
            if (image) {
                formData.append('image', image);
            }
    
            console.log("Form Data:", Object.fromEntries(formData.entries()));
    
            await axios.post('/genx/hall/sponsor', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            onClose();
        } catch (error) {
            console.error('Fehler beim Erstellen des Gerichts:', error);
            setError('Beim Erstellen des Gerichts ist ein Fehler aufgetreten.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex flex-col absolute rounded-l-[12px] top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]">
            <div className='flex w-full justify-between'>
                <h2 className='font-semibold'>Neuer Sponsor erstellen</h2>
                <button onClick={onClose}>Schließen</button>
            </div>
            <div className='flex flex-col gap-[8px]'>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col">
                        <label htmlFor="image" className="block mb-1">Logo</label>
                        <span className='text-[12px] font-gray-500 mb-2'>Das Logo muss min. 100px x 100px groß sein</span>
                        {error && <p className="error-message mb-2 text-[12px] text-red-500">{error}</p>}
                        <div className="relative flex items-center justify-center h-[140px] w-[140px] border rounded-[12px] border-gray-600 cursor-pointer mb-[16px]">
                            <input 
                                type="file" 
                                id="image" 
                                accept="image/*" 
                                onChange={handleImageChange} 
                                className="absolute inset-0 opacity-0 cursor-pointer" 
                                ref={fileInputRef}
                                required
                            />
                            {imagePreviewUrl ? (
                                <>
                                    <img 
                                        src={imagePreviewUrl} 
                                        alt="Vorschau" 
                                        className="object-cover w-full h-full"
                                    />
                                    <button 
                                        type="button" 
                                        onClick={handleImageRemove} 
                                        className="absolute top-2 right-2 bg-white flex w-[20px] h-[20px] text-[12px] border border-gray-400 p-1 rounded-full text-gray-900"
                                    >
                                        <p className='m-auto'>X</p>
                                    </button>
                                </>
                            ) : (
                                <span>Bild hochladen</span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="width" className="mb-2">Name:</label>
                        <input 
                            type="text" 
                            id="width" 
                            value={sponsname} 
                            onChange={(e) => setSponsName(e.target.value)}
                            className="border border-gray-600 rounded-[8px] p-2"
                            placeholder="Name des Sponsors" 
                            required 
                        />
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="website-url" className="mb-2">Webseite:</label>
                        <input 
                            type="text" 
                            id="website-url" 
                            value={url} 
                            onChange={(e) => setUrl(e.target.value)}
                            className="border border-gray-600 rounded-[8px] p-2" 
                            placeholder="Webseiten-URL des Sponsor" 
                            required 
                        />
                    </div>
                    <div className="form-actions flex w-full justify-between">
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Erstelle...' : 'Erstellen'}
                        </button>
                        <button type="button" onClick={onClose}>Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SponsorCreate;