import React, { useState } from 'react';

const HourTimePick = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const times = Array.from({ length: 23 }, (_, i) => `${String(i + 1).padStart(2, '0')}:00`);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (selectedTime) => {
    onChange(selectedTime);
    setIsOpen(false);
  };

  const scrollToValue = (dropdownRef, value) => {
    if (dropdownRef && value) {
      const index = times.indexOf(value);
      if (index > 0) {
        dropdownRef.scrollTop = index * 40;
      }
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        readOnly
        value={value}
        onClick={toggleDropdown}
        className="cursor-pointer text-center border flex w-[80px] border-gray-300 rounded p-2"
      />
      {isOpen && (
        <div
          className="absolute mt-1 max-h-40 w-full overflow-y-auto bg-white border border-gray-300 rounded shadow-lg z-10"
          ref={(ref) => scrollToValue(ref, value)}
        >
          {times.map((time) => (
            <div
              key={time}
              onClick={() => handleSelect(time)}
              className={`p-2 text-center cursor-pointer hover:bg-gray-200 ${
                time === value ? 'bg-gray-300' : ''
              }`}
            >
              {time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HourTimePick;