import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';

const ShareLink = () => {
    const { user } = useContext(UserContext)
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchOrCreateLink = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/genx/share-link/${user.userId}`);
                setLink(response.data.link);
                setError('');
            } catch (err) {
                console.error('Fehler beim Abrufen oder Erstellen des Links:', err);
                setError('Fehler beim Abrufen oder Erstellen des Links');
            } finally {
                setLoading(false);
            }
        };

        fetchOrCreateLink();
    }, []);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                alert('Link kopiert!');
            })
            .catch((err) => {
                console.error('Fehler beim Kopieren des Links:', err);
                alert('Fehler beim Kopieren des Links');
            });
    };

    return (
        <div className='flex flex-col booking-form-container absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1001] w-[480px] bg-white p-[36px]'>
            <h4>Share Link</h4>
            <p>Du kannst mit dem Link neue Leute als Trainer einladen.</p>
            <div>
                <input
                    type="text"
                    value={link}
                    readOnly
                    className="border border-gray-300 p-2 rounded-md w-full"
                />
                <button
                    onClick={handleCopyClick}
                    className="mt-2 bg-blue-500 text-white p-2 rounded-md"
                >
                    {loading ? 'Kopiere...' : 'Copy'}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    );
};

export default ShareLink;