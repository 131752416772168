import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './Dashboard.css';
import Navigation from '../components/Navigation';
import Home from './HomeContent';
import Planung from './PlanungContent';
import Finanzen from './FinanzenContent';
import Profile from './ProfileContent';
import SubNavigation from '../components/SubNavigation';
import Events from './EventsContent';
import NewBooking from '../components/noticfications/NewBooking';
import MessageBx from '../components/noticfications/MessageBx';
import Header from '../components/Header';

const Dashboard = ({ onLogout }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let ws;

    if (window.location.hostname === 'localhost') {
      ws = new WebSocket('ws://localhost:8011');
    } else {
      ws = new WebSocket('wss://next.agiplus.de');
    }

    ws.onopen = () => {
      console.log('Connected to WebSocket');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.error) {
        console.error('WebSocket Error:', data.error);
      } else {
        setMessages((prevMessages) => [...prevMessages, ...data]);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
  }, []);

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className='flex w-[100vw] h-[100vh] overflow-hidden'>
      <Navigation onLogout={onLogout} />
      <SubNavigation />
      <div className='flex wcontent flex-col'>
        <Header />
        <div className='flex flex-col p-[28px] w-full hcontent overflow-y-scroll'>
          <Routes>
            <Route path="/home/*" element={<Home />} />
            <Route path="/planing/*" element={<Planung />} />
            <Route path="/events/*" element={<Events />} />
            <Route path="/finanz/*" element={<Finanzen />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/home" element={<Navigate to="/home/overview" />} />
            <Route path="/planing" element={<Navigate to="/planing/overview" />} />
            <Route path="/events" element={<Navigate to="/events/overview" />} />
            <Route path="/finanz" element={<Navigate to="/finanz/overview" />} />
            <Route path="/profile" element={<Navigate to="/profile/overview" />} />
            <Route path="/*" element={<Navigate to="/home/overview" />} />
          </Routes>
        </div>
      </div>
      <button className='absolute bottom-8 right-8 flex flex-col w-fit h-fit' onClick={handleButtonClick}>
          {isVisible && (
            <div className='mb-[16px] bg-white border rounded-[8px]'>
              <NewBooking />
            </div>
          )}
          <div className='relative mr-0 ml-auto bg-white border rounded-[8px] p-[8px]'>
            <img src="../assets/components/noticfications/newbooking.png" alt="Notification Icon"/>
            <div className='bg-red-600 w-[16px] h-[16px] rounded-[50%] absolute top-0 right-0 translate-x-[50%] translate-y-[-50%]'></div>
          </div>
        </button>
        <div id="messages" className='absolute bottom-8 left-[50%] translate-x-[-50%]'>
          {messages.map((message, index) => (
            <MessageBx tpye={index} message={message} />
          ))}
        </div>
    </div>
  );
};

export default Dashboard;