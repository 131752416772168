import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { UserContext } from '../../context/UserContext';

const HallInfos = () => {
    const [edithallName, setEditHallName] = useState(false);
    const [edithallInfos, setEditHallInfos] = useState(false);
    const [error, setError] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const fileInputRef = useRef(null);
    const { user } = useContext(UserContext);
    
    // Default values
    const defaultAddress = {
        street: '',
        housenr: '',
        postcode: '',
        city: ''
    };
    const defaultFacilities = {
        parking: false,
        accommodation: false,
        toilets: false,
        bistro: false,
        gasPaths: false,
        physiotherapy: false,
        shop: false
    };

    const facilitiesName = {
        parking: "Parkplätze",
        accommodation: "Übernachtung",
        toilets: "Toileten",
        bistro: "Bistro",
        gasPaths: "Gasiwege",
        physiotherapy: "Physiotherapie",
        shop: "Shop"
    }

    const [hallName, setHallName] = useState(user.name);
    const [hallEmail, setHallEmail] = useState(user.email);
    const [address, setAddress] = useState(defaultAddress);
    const [facilities, setFacilities] = useState(defaultFacilities);

    useEffect(() => {
        const fetchHallSettings = async () => {
            try {
                const token = Cookies.get('token');

                const response = await axios.get('/genx/hall/settings', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (!response.data || response.data === null) {
                    await axios.post('/genx/hall/settings', {
                        address: defaultAddress,
                        facilities: defaultFacilities
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                } else {
                    setAddress({
                        street: response.data.address?.street || defaultAddress.street,
                        housenr: response.data.address?.housenr || defaultAddress.housenr,
                        postcode: response.data.address?.postcode || defaultAddress.postcode,
                        city: response.data.address?.city || defaultAddress.city
                    });
                    setFacilities({
                        parking: response.data.facilities?.parking ?? defaultFacilities.parking,
                        accommodation: response.data.facilities?.accommodation ?? defaultFacilities.accommodation,
                        toilets: response.data.facilities?.toilets ?? defaultFacilities.toilets,
                        bistro: response.data.facilities?.bistro ?? defaultFacilities.bistro,
                        gasPaths: response.data.facilities?.gasPaths ?? defaultFacilities.gasPaths,
                        physiotherapy: response.data.facilities?.physiotherapy ?? defaultFacilities.physiotherapy,
                        shop: response.data.facilities?.shop ?? defaultFacilities.shop
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchHallSettings();
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            img.onload = () => {
                if (img.width < 100 || img.height < 100) {
                    setError('Das Bild muss mindestens 100px x 100px groß sein.');
                    setImage(null);
                    setImagePreviewUrl('');
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                } else {
                    const squareSize = Math.min(img.width, img.height);
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    canvas.width = squareSize;
                    canvas.height = squareSize;

                    ctx.drawImage(
                        img,
                        (img.width - squareSize) / 2,
                        (img.height - squareSize) / 2,
                        squareSize,
                        squareSize,
                        0,
                        0,
                        squareSize,
                        squareSize
                    );

                    canvas.toBlob((blob) => {
                        if (blob) {
                            setImage(new File([blob], file.name, { type: file.type }));
                            setImagePreviewUrl(URL.createObjectURL(blob));
                            setError('');
                        }
                    }, file.type);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = () => {
        setImage(null);
        setImagePreviewUrl('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setFacilities(prev => ({
            ...prev,
            [id]: checked
        }));
    };

    const handleSave = async () => {
        try {
            const token = Cookies.get('token');
            await axios.post('/genx/hall/settings', {
                name: hallName,
                email: hallEmail,
                address,
                facilities
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setEditHallInfos(false);
        } catch (error) {
            console.error(error);
            setError('Fehler beim Speichern. Bitte versuche es später erneut.');
        }
    };

    return (
        <>
            <div>
                <div className="flex flex-col justify-between mb-16">
                    <div className="flex flex-col mb-8">
                        <h2 className='text-[18px] font-semibold mb-2'>Grundinfos</h2>
                        <p>Hier gibt es einige Grundeinstellungen.</p>
                    </div>
                    <div className='flex flex-col gap-[32px]'>
                        <div className='flex'>
                            <div className='w-1/2'>
                                <h4>Allgemein</h4>
                            </div>
                            <div className='w-1/2 flex flex-col gap-[24px]'>
                                <div className='flex'>
                                    {/*<label htmlFor="image" className="w-[140px] mr-[8px]">Logo</label>
                                    <div className='w-[240px] mr-[8px] my-auto'>
                                        <div className="relative flex items-center justify-center h-[120px] w-[120px] border rounded-[12px] border-gray-300 cursor-pointer">
                                            <input 
                                                type="file" 
                                                id="image" 
                                                accept="image/*" 
                                                onChange={handleImageChange} 
                                                className="absolute inset-0 opacity-0 cursor-pointer" 
                                                ref={fileInputRef}
                                            />
                                            {imagePreviewUrl ? (
                                                <>
                                                    <img 
                                                        src={imagePreviewUrl} 
                                                        alt="Vorschau" 
                                                        className="object-cover w-full h-full"
                                                    />
                                                    <button 
                                                        type="button" 
                                                        onClick={handleImageRemove} 
                                                        className="absolute top-2 right-2 bg-white flex w-[20px] h-[20px] border border-gray-400 p-1 rounded-[8px] text-gray-900 text-[12px]"
                                                    >
                                                        <p className='m-auto'>X</p>
                                                    </button>
                                                </>
                                            ) : (
                                                <span className='text-[12px]'>Bild hochladen</span>
                                            )}
                                        </div>
                                    </div>*/}
                                    <button className='mt-0 mb-auto ml-auto mr-0 p-[8px] border rounded-[6px]' onClick={() => {
                                        if (edithallInfos) {
                                            handleSave();
                                        } else {
                                            setEditHallInfos(!edithallInfos);
                                        }
                                    }}>
                                        {edithallInfos ? "Speichern" : "Bearbeiten"}
                                    </button>
                                </div>
                                <div className='flex w-full justify-between'>
                                    <div className='flex'>
                                        <label className='w-[140px] mr-[8px] my-auto'>Name</label>
                                        <div className='w-[240px] mr-[8px] my-auto'>
                                            {edithallInfos ? (
                                                <input
                                                    className='border rounded-[8px] w-[100%] px-[12px] py-[8px]'
                                                    type="text"
                                                    value={hallName}
                                                    onChange={(e) => setHallName(e.target.value)}
                                                    placeholder="Name eingeben"
                                                />
                                            ) : (
                                                <p className='w-[100%] px-[12px] py-[8px]'>{hallName || 'Name nicht angegeben'}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <label className='w-[140px] mr-[8px] my-auto'>Account E-Mail</label>
                                    <div className='w-[240px] mr-[8px] my-auto'>
                                        {edithallInfos ? (
                                            <input
                                                className='border rounded-[8px] w-[100%] px-[12px] py-[8px]'
                                                type="text"
                                                value={hallEmail}
                                                onChange={(e) => setHallEmail(e.target.value)}
                                                placeholder="E-Mail eingeben"
                                            />
                                        ) : (
                                            <p className='w-[100%] px-[12px] py-[8px]'>{hallEmail || 'E-Mail nicht angegeben'}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <label className='w-[140px] mr-[8px] '>Adresse</label>
                                    <div className='w-[240px] mr-[8px] my-auto flex flex-col gap-[8px]'>
                                        {edithallInfos ? (
                                            <div className='flex gap-2'>
                                                <input
                                                    className='border rounded-[8px] w-[80%] px-[12px] py-[8px]'
                                                    type="text"
                                                    value={address.street}
                                                    onChange={(e) => setAddress({...address, street: e.target.value})}
                                                    placeholder="Straße eingeben"
                                                />
                                                <input
                                                    className='border rounded-[8px] w-[20%] px-[12px] py-[8px]'
                                                    type="text"
                                                    value={address.housenr}
                                                    onChange={(e) => setAddress({...address, housenr: e.target.value})}
                                                    placeholder="Nr. eingeben"
                                                />
                                            </div>
                                        ) : (
                                            <p className='w-[100%] px-[12px] py-[8px]'>{address.street + ` ` + address.housenr || 'Adresse nicht angegeben'}</p>
                                        )}
                                        {edithallInfos ? (
                                            <input
                                                className='border rounded-[8px] w-[100%] px-[12px] py-[8px]'
                                                type="text"
                                                value={address.postcode}
                                                onChange={(e) => setAddress({...address, postcode: e.target.value})}
                                                placeholder="Postleitzahl eingeben"
                                            />
                                        ) : (
                                            <p className='w-[100%] px-[12px] py-[8px]'>{address.postcode || 'PLZ nicht angegeben'}</p>
                                        )}
                                        {edithallInfos ? (
                                            <input
                                                className='border rounded-[8px] w-[100%] px-[12px] py-[8px]'
                                                type="text"
                                                value={address.city}
                                                onChange={(e) => setAddress({...address, city: e.target.value})}
                                                placeholder="Stadt eingeben"
                                            />
                                        ) : (
                                            <p className='w-[100%] px-[12px] py-[8px]'>{address.city || 'Stadt nicht angegeben'}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='w-1/2'>
                                <h4>Ausstattung</h4>
                            </div>
                            <div className='w-1/2 flex flex-col gap-[24px]'>
                            {Object.keys(defaultFacilities).map((key) => (
                                    <div className='flex gap-[8px]' key={key}>
                                        <div className='my-auto'>
                                            <input 
                                                type="checkbox" 
                                                id={key} 
                                                name={key} 
                                                checked={facilities[key]} 
                                                onChange={edithallInfos ? handleCheckboxChange : null}
                                                disabled={!edithallInfos}
                                            />
                                        </div>
                                        <label htmlFor={key} className='w-[140px] mr-[8px]'>{facilitiesName[key].charAt(0).toUpperCase() + facilitiesName[key].slice(1)}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HallInfos;