import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SubNavigation = () => {
    const location = useLocation();
    const path = location.pathname;
  
    const renderSubNavigation = () => {
        if (path.startsWith('/home')) {
            return (
                <div className="">
                    <ul className="flex flex-col gap-[40px]">
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Allgemein</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/home/overview">Übersicht</Link></li>
                                        <li><Link to="/home/hall">Hundehalle</Link></li>
                                        <li><Link to="/home/media">Medien</Link></li>
                                        <li><Link to="/home/contact">Ansprechpartner</Link></li>
                                        <li><Link to="/home/website">Webseite</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            );
        } else if (path.startsWith('/planing')) {
            return (
                <div className="sub-navigation-content">
                    <ul className="flex flex-col gap-[40px]">
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Allgemein</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/planing/overview/calender" className={`${path === '/planing/overview/calender' ? 'underline' : ''}`}>Kalender</Link></li>
                                        <li><Link to="/planing/overview/statistic" className={`${path === '/planing/overview/statistic' ? 'underline' : ''}`}>Statistik</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Buchungen</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/planing/booking?view=all">Alle</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Training</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                    <li><Link to="/planing/coaches?view=coaches">Trainer</Link></li>
                                        <li><Link to="/planing/coaches?view=plan">Trainingsplan</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            );
        } else if (path.startsWith('/events')) {
            return (
                <div className="sub-navigation-content">
                    <ul className="flex flex-col gap-[40px]">
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Allgemein</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/events/statistic">Statistik</Link></li>
                                        <li><Link to="/events/overview/calender">Kalender</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Turniere</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/events/competitions">Übersicht</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Seminare</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/events/seminars">Übersicht</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="flex flex-col gap-[24px]">
                                <li><h5 className='text-[16px] font-bold'>Veranstaltungen</h5></li>
                                <li>
                                    <ul className='flex flex-col ml-[24px] gap-[16px]'>
                                        <li><Link to="/events/special">Übersicht</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            );
        }
    };
    return (
        <div className='sub-navigation flex w-[243px] h-[100vh] border py-[24px] px-[28px]'>
            {renderSubNavigation()}
        </div>
    );
};

export default SubNavigation;