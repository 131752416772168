import React, { useState, useEffect } from 'react';
import './FiveDayCalender.css';
import { addWeeks, format, startOfWeek, endOfWeek, isSameDay, parse, addMinutes } from 'date-fns';
import Cookies from 'js-cookie';
import axios from 'axios';

const hours = Array.from({ length: 16 }, (_, i) => {
  const hour = 7 + i;
  return `${hour}:00`;
});

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function FiveDayCalender() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([]);

    const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });

    const handlePrevWeek = () => {
        setCurrentDate(addWeeks(currentDate, -1));
    };

    const handleNextWeek = () => {
        setCurrentDate(addWeeks(currentDate, 1));
    };

    const getEventsForDayAndTime = (day, time) => {
        const date = format(addWeeks(startOfWeekDate, 0), 'yyyy-MM-dd');
        return events.filter(event => {
        const eventDate = parse(event.date, 'yyyy-MM-dd', new Date());
        const eventStart = parse(event.startTime, 'HH:mm', eventDate);
        const eventEnd = parse(event.endTime, 'HH:mm', eventDate);
        const cellTime = parse(time, 'HH:mm', eventDate);
        return (
            isSameDay(eventDate, addMinutes(startOfWeekDate, day * 1440)) &&
            cellTime >= eventStart &&
            cellTime < eventEnd
        );
        });
    };

    return (
        <div className="calendar">
        <div className="navigation">
            <button onClick={handlePrevWeek}>Previous Week</button>
            <span>
            {format(startOfWeekDate, 'dd MMM yyyy')} - {format(endOfWeekDate, 'dd MMM yyyy')}
            </span>
            <button onClick={handleNextWeek}>Next Week</button>
        </div>
        <div className="header">
            <div className="time-column-header"></div>
            {days.map(day => (
            <div key={day} className="day-header">
                {day}
            </div>
            ))}
        </div>
        <div className="body">
            <div className="time-column">
            {hours.map(time => (
                <div key={time} className="time-cell">
                {time}
                </div>
            ))}
            </div>
            {days.map((day, dayIndex) => (
            <div key={day} className="day-column">
                {hours.map((time, index) => (
                <div key={index} className="day-cell">
                    {getEventsForDayAndTime(dayIndex, time).map(event => (
                    <div key={event.id} className="event">
                        {event.title}
                    </div>
                    ))}
                </div>
                ))}
            </div>
            ))}
        </div>
        </div>
    );
}

export default FiveDayCalender;
