import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const CourtDetails = ({ id, onClose, onSuccess }) => {
    const [formData, setFormData] = useState({
        hallId: '',
        width: '',
        length: '',
        surfaceType: [],
        sportType: [],
        imageUrl: ''
    });
    const [selectedSurfaceTypes, setSelectedSurfaceTypes] = useState([]);
    const [selectedSportTypes, setSelectedSportTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [surfaceTypeOptions, setSurfaceTypeOptions] = useState(["Rasen", "Kunstrasen", "Sand"]);
    const [sportTypeOptions, setSportTypeOptions] = useState(["Agility", "Rally Obidience", "IGP", "Sonstiges"]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            setBaseUrl('http://localhost:8011');
        } else {
            setBaseUrl('https://next.agiplus.de/api/v1');
        }
    }, []);

    useEffect(() => {
        const fetchCourtDetails = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get(`/genx/hall/courts/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const court = response.data;
                setFormData({
                    hallId: court.hallId,
                    width: court.dimensions.width,
                    length: court.dimensions.length,
                    surfaceType: court.surfaceType.split(', '),
                    sportType: court.sportType,
                    imageUrl: court.imageUrl
                });
                setSelectedSurfaceTypes(court.surfaceType.split(', '));
                setSelectedSportTypes(court.sportType);
                setImagePreviewUrl(court.imageUrl);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching court details:', error);
                setError('Fehler beim Laden der Feld Details.');
            }
        };

        fetchCourtDetails();
    }, [id]);

    const handleSave = async () => {
        try {
            await axios.put(`/sponsor/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Buchung:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/sponsor/${id}`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            onClose();
        } catch (error) {
            console.error('Fehler beim Löschen der Buchung:', error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSurfaceTypeChange = (event) => {
        const selected = [...event.target.selectedOptions].map(option => option.value);
        setFormData({
            ...formData,
            surfaceType: selected
        });
        setSelectedSurfaceTypes(selected);
    };

    const removeSurfaceType = (type) => {
        const updatedSurfaceTypes = selectedSurfaceTypes.filter(selected => selected !== type);
        setSelectedSurfaceTypes(updatedSurfaceTypes);
        setFormData({
            ...formData,
            surfaceType: updatedSurfaceTypes
        });
        setSurfaceTypeOptions([...surfaceTypeOptions, type]);
    };

    const handleSportTypeChange = (event) => {
        const selected = [...event.target.selectedOptions].map(option => option.value);
        setFormData({
            ...formData,
            sportType: selected
        });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setFormData({ ...formData, imageUrl: URL.createObjectURL(file) });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { hallId, width, length, surfaceType, sportType, imageUrl } = formData;
        const courtData = {
            hallId,
            dimensions: {
                width: parseFloat(width),
                length: parseFloat(length)
            },
            surfaceType: surfaceType.join(', '),
            sportType,
            imageUrl
        };

        try {
            const response = await axios.put(`/genx/hall/court/${id}`, courtData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            });
            console.log('Court updated successfully:', response.data);
            onClose();
        } catch (error) {
            console.error('Error updating court:', error);
            setError('Fehler beim Aktualisieren des Feldes.');
        }
    };

    if (loading) return (
        <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
            <p>Loading ...</p>
        </div>
    );

    return (
        <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
            <h2>Feld Details</h2>
            {error && <p className="error-message mb-2 text-[12px] text-red-500">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Breite (in Meter):</label>
                    <input
                        type="number"
                        name="width"
                        value={formData.width}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Länge (in Meter):</label>
                    <input
                        type="number"
                        name="length"
                        value={formData.length}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="flex flex-col mb-[16px]">
                    {selectedSurfaceTypes.length === 0 ? (
                        <select id="surfaceType" className="border border-gray-600 rounded-[8px] p-2" onChange={handleSurfaceTypeChange}>
                            <option value="">Wählen Sie eine Oberfläche</option>
                            {surfaceTypeOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    ) : (
                        <label>Oberfläche:</label>
                    )}
                    {selectedSurfaceTypes.length > 0 && (
                        <div>
                            {selectedSurfaceTypes.map(type => (
                                <div key={type} className="flex items-center justify-between mt-1">
                                    <span>{type}</span>
                                    <button 
                                        type="button" 
                                        onClick={() => removeSurfaceType(type)} 
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        Entfernen
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div>
                    {selectedSportTypes.length  === 0 ? (
                        <>
                            <label>Sportarten:</label>
                            <select
                                multiple
                                name="sportType"
                                value={formData.sportType}
                                onChange={handleSportTypeChange}
                                required
                            >
                                {sportTypeOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </>
                    ) : (
                        <label>Sportarten:</label>
                    )}
                    {selectedSportTypes.length > 0 && (
                        <div>
                            {selectedSportTypes.map(type => (
                                <div key={type} className="flex items-center justify-between mt-1">
                                    <span>{type}</span>
                                    <button 
                                        type="button" 
                                        onClick={() => removeSurfaceType(type)} 
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        Entfernen
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div>
                    {!imagePreviewUrl ? (
                        <>
                            <label>Image:</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </>
                    ) : (
                        <div className="relative flex items-center justify-center h-[140px] w-[140px] border border-gray-300 mt-2">
                            <img
                                src={`${baseUrl}${imagePreviewUrl}`}
                                alt="Preview"
                                className="object-cover w-full h-full"
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    setImagePreviewUrl('');
                                    setFormData({ ...formData, imageUrl: '' });
                                }}
                                className="absolute top-2 right-2 bg-white flex w-[20px] h-[20px] text-[12px] border border-gray-400 p-1 rounded-full text-gray-900"
                            >
                                <p className='m-auto'>X</p>
                            </button>
                        </div>
                    )}
                </div>
                <div className='flex w-full justify-between'>
                    {isEditing ? (
                        <>
                            <button onClick={handleSave}>Speichern</button>
                            <button onClick={handleCancelClick}>Abbrechen</button>
                        </>
                    ) : (
                        <button onClick={handleEditClick}>Bearbeiten</button>
                    )}
                    <button onClick={handleDelete}>Löschen</button>
                </div>
            </form>
        </div>
    );
};

export default CourtDetails;