import axios from 'axios';
import React, { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';

const BookingForm = ({ closeForm }) => {
    const { user } = useContext(UserContext);
    const userId = user.userId;
    const [formData, setFormData] = useState({
        athleteId: '',
        name: '',
        email: '',
        fieldName: '',
        bookingDate: '',
        startTime: '',
        endTime: '',
        price: '',
        bookingstatus: 'accept',
    });

    const handleChange = (ev) => {
        const { name, value } = ev.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        onSubmit(formData);
    };

    const onSubmit = async (formData) => {
        try {
            const response = await axios.post(`/booking/${userId}`, formData);
            if(response.status === 201){
                alert('Die Buchung wurde erfolgreich ausgeführt');
                window.location.reload();
            }
        } catch (error) {
            alert('Es gab ein Problem beim Versenden der Buchung');
        }
    }

    return (
        <div className="flex flex-col booking-form-container absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1001] w-[480px] bg-white p-[36px]">
            <h2 className='mx-auto mb-[24px] text-[24px] font-bold'>Buchung erstellen</h2>
            <form onSubmit={handleSubmit} className='flex flex-col gap-[12px]'>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>AgiPlus-ID (optional):</label>
                    <input
                        type="text"
                        name="athleteId"
                        className='border p-[8px] text-[14px]'
                        value={formData.athleteId}
                        onChange={handleChange}
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Name:</label>
                    <input
                        type="text"
                        name="name"
                        className='border p-[8px] text-[14px]'
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Email:</label>
                    <input
                        type="email"
                        name="email"
                        className='border p-[8px] text-[14px]'
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Feldname:</label>
                    <select
                        name="fieldName"
                        value={formData.fieldName}
                        className='border p-[8px] text-[14px]'
                        onChange={handleChange}
                        required
                    >
                        <option value="">Wähle ein Feld</option>
                        <option value="Feld 1">Feld 1</option>
                        <option value="Feld 2">Feld 2</option>
                        <option value="Feld 3">Feld 3</option>
                    </select>
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Buchungsdatum:</label>
                    <input
                        type="date"
                        name="bookingDate"
                        className='border p-[8px] text-[14px]'
                        value={formData.bookingDate}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Startzeit:</label>
                    <input
                        type="time"
                        name="startTime"
                        className='border p-[8px] text-[14px]'
                        value={formData.startTime}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Endzeit:</label>
                    <input
                        type="time"
                        name="endTime"
                        className='border p-[8px] text-[14px]'
                        value={formData.endTime}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex flex-col'>
                    <label className='mb-[8px]'>Preis:</label>
                    <input
                        type="number"
                        name="price"
                        className='border p-[8px] text-[14px]'
                        value={formData.price}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='flex gap-[24px] mx-auto'>
                    <button type="submit" className='border p-[8px] text-[14px]'>Buchung erstellen</button>
                    <button type="button" onClick={closeForm}>Abbrechen</button>
                </div>
            </form>
        </div>
    );
};

export default BookingForm;