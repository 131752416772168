import React from "react";

const MessageBx = ({ type, message }) => {
    let ic;
    
    if (type === 'error') {
        ic = '../assets/components/noticfications/error.png'
    } else {
        ic = '../assets/components/noticfications/success.png'
    }

    return (
        <div className={`flex w-fit h-fit px-[16px] py-[14px] rounded-[8px] ${type === 'success' ? 'bg-[#42B679]' : ''} ${type === 'warning' ? 'bg-[#8B88D2]' : ''} ${type === 'error' ? 'bg-[#da6a6a]' : ''} `}>
            <img src={ic} className="flex w-[16px] h-[16px] mr-[8px]" alt="message icon"/>
            <p className="text-white text-[14px] my-auto">{message}</p>
        </div>
    );
}

export default MessageBx;