import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BookingDetails = ({ id, onClose }) => {
    const [bookingDetails, setBookingDetails] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const response = await axios.get(`/booking/${id}`);
                setBookingDetails(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Buchungsdetails:', error);
            }
        };

        fetchBookingDetails();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(`/booking/${id}`, bookingDetails);
            setIsEditing(false);
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Buchung:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/booking/${id}`);
            onClose();
        } catch (error) {
            console.error('Fehler beim Löschen der Buchung:', error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    if (!bookingDetails) {
        return (
            <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
                <div className='flex w-full justify-between'>
                    <h2>Details der Buchung</h2>
                    <button onClick={onClose}>Schließen</button>
                </div>
                <p>Lade Buchungsdetails...</p>
            </div>
        );
    }

    return (
        <div className='flex flex-col absolute top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]'>
            <div className='flex w-full justify-between'>
                <h2>Details der Buchung</h2>
                <button onClick={onClose}>Schließen</button>
            </div>
            <div className='flex flex-col gap-[8px]'>
                <p><strong>Id:</strong> {bookingDetails._id}</p>
                <label>
                    <strong>Name:</strong>
                    {isEditing ? (
                        <input 
                            type="text" 
                            name="name" 
                            className='border rounded-[8px] p-[8px]'
                            value={bookingDetails.name} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.name}</span>
                    )}
                </label>
                <label>
                    <strong>Email:</strong>
                    {isEditing ? (
                        <input 
                            type="email" 
                            name="email"
                            className='border rounded-[8px] p-[8px]' 
                            value={bookingDetails.email} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.email}</span>
                    )}
                </label>
                <label>
                    <strong>Feldname:</strong>
                    {isEditing ? (
                        <input 
                            type="text" 
                            name="fieldName" 
                            className='border rounded-[8px] p-[8px]'
                            value={bookingDetails.fieldName} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.fieldName}</span>
                    )}
                </label>
                <label>
                    <strong>Startzeit:</strong>
                    {isEditing ? (
                        <input 
                            type="text" 
                            name="startTime"
                            className='border rounded-[8px] p-[8px]' 
                            value={bookingDetails.startTime} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.startTime}</span>
                    )}
                </label>
                <label>
                    <strong>Endzeit:</strong>
                    {isEditing ? (
                        <input 
                            type="text" 
                            name="endTime"
                            className='border rounded-[8px] p-[8px]' 
                            value={bookingDetails.endTime} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.endTime}</span>
                    )}
                </label>
                <label>
                    <strong>Datum:</strong>
                    {isEditing ? (
                        <input 
                            type="date" 
                            name="bookingDate"
                            className='border rounded-[8px] p-[8px]' 
                            value={new Date(bookingDetails.bookingDate).toISOString().split('T')[0]} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{new Date(bookingDetails.bookingDate).toLocaleDateString('de-DE')}</span>
                    )}
                </label>
                <label>
                    <strong>Preis:</strong>
                    {isEditing ? (
                        <input 
                            type="number" 
                            name="price" 
                            className='border rounded-[8px] p-[8px]'
                            value={bookingDetails.price} 
                            onChange={handleChange} 
                        />
                    ) : (
                        <span>{bookingDetails.price} €</span>
                    )}
                </label>
                <label>
                    <strong>Status:</strong>
                    {isEditing ? (
                        <select 
                            name="bookingstatus" 
                            className='border rounded-[8px] p-[8px]' 
                            value={bookingDetails.bookingstatus} 
                            onChange={handleChange}
                        >
                            <option value="open">Offen</option>
                            <option value="accept">Akzeptiert</option>
                            <option value="payed">Bezahlt</option>
                            <option value="live">Live</option>
                        </select>
                    ) : (
                        <span>
                            {bookingDetails.bookingstatus === "open" && "Offen"}
                            {bookingDetails.bookingstatus === "accept" && "Akzeptiert"}
                            {bookingDetails.bookingstatus === "payed" && "Bezahlt"}
                            {bookingDetails.bookingstatus === "live" && "Live"}
                        </span>
                    )}
                </label>
            </div>
            <div className='flex w-full justify-between'>
                {isEditing ? (
                    <>
                        <button onClick={handleSave}>Speichern</button>
                        <button onClick={handleCancelClick}>Abbrechen</button>
                    </>
                ) : (
                    <button onClick={handleEditClick}>Bearbeiten</button>
                )}
                <button onClick={handleDelete}>Löschen</button>
            </div>
        </div>
    );
}

export default BookingDetails;