import React from 'react';

const Loading = () => {
  return (
    <div>
      <h1>Du wirst mit dem Dienst verbunden</h1>
      <p>Bitte einen Moment Geduld, während wir deine Verbindung herstellen...</p>
    </div>
  );
};

export default Loading;