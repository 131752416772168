import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Switcher from '../switcher/Switcher';
import HourTimePick from '../inputs/HourTimePick';

const OpeningHoursForm = () => {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [loading, setLoading] = useState(true);

  const defaultData = {
    regularHours: {
      monday: { open: '08:00', close: '18:00', active: true },
      tuesday: { open: '08:00', close: '18:00', active: true },
      wednesday: { open: '08:00', close: '18:00', active: true },
      thursday: { open: '08:00', close: '18:00', active: true },
      friday: { open: '08:00', close: '18:00', active: true },
      saturday: { open: '09:00', close: '15:00', active: true },
      sunday: { open: '10:00', close: '14:00', active: true },
    },
    holidays: [],
    customDates: [],
    weekends: {
      saturday: { open: '09:00', close: '15:00' },
      sunday: { open: '10:00', close: '14:00' },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('token');
      try {
        const response = await axios.get(`/genx/hall/opening-hours`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data && Object.keys(response.data).length > 0) {
          const dataWithActive = {
            ...response.data,
            regularHours: Object.keys(response.data.regularHours).reduce((acc, day) => {
              const hours = response.data.regularHours[day];
              acc[day] = {
                ...hours,
                active: !!hours.open && !!hours.close,
              };
              return acc;
            }, {}),
          };
  
          setFormData(dataWithActive);
          setOriginalData(JSON.stringify(dataWithActive));
        } else {
          await createDefaultEntry(token);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Öffnungszeiten:', error);
        await createDefaultEntry(token);
      } finally {
        setLoading(false);
      }
    };
  
    const createDefaultEntry = async (token) => {
      try {
        const response = await axios.post(`/genx/hall/opening-hours`, defaultData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        // Direkt die neu erstellten Daten setzen, damit sie im Formular angezeigt werden
        setFormData(response.data);
        setOriginalData(JSON.stringify(response.data));
      } catch (saveError) {
        console.error('Fehler beim Speichern der Standardwerte:', saveError);
      }
    };
  
    fetchData();
  }, []);  

  if (loading) return <p>Lädt...</p>;

  if (!formData) return <p>Fehler beim Laden der Daten.</p>;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleHoursChange = (day, type, value) => {
    setFormData({
      ...formData,
      regularHours: {
        ...formData.regularHours,
        [day]: {
          ...formData.regularHours[day],
          [type]: value,
        },
      },
    });
  };

  const handleHolidayChange = (index, e) => {
    const { name, value } = e.target;
    const newHolidays = [...formData.holidays];
    newHolidays[index][name] = value;
    setFormData({
      ...formData,
      holidays: newHolidays,
    });
  };

  const handleCustomDateChange = (index, e) => {
    const { name, value } = e.target;
    const newCustomDates = [...formData.customDates];
    newCustomDates[index][name] = value;
    setFormData({
      ...formData,
      customDates: newCustomDates,
    });
  };

  const addHoliday = () => {
    setFormData({
      ...formData,
      holidays: [
        ...formData.holidays,
        { date: '', open: '00:00', close: '23:59' },
      ],
    });
  };

  const addCustomDate = () => {
    setFormData({
      ...formData,
      customDates: [
        ...formData.customDates,
        { date: '', open: '00:00', close: '23:59' },
      ],
    });
  };

  const removeHoliday = (index) => {
    const newHolidays = formData.holidays.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      holidays: newHolidays,
    });
  };

  const removeCustomDate = (index) => {
    const newCustomDates = formData.customDates.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      customDates: newCustomDates,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get('token');
      await axios.put(`/genx/hall/opening-hours`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Daten erfolgreich gespeichert!');
      setOriginalData(JSON.stringify(formData));
    } catch (error) {
      console.error('Fehler beim Speichern der Daten:', error);
    }
  };

  const dayTranslations = {
    sunday: 'Sonntag',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
  };

  const handleActiveToggle = (day) => {
    setFormData(prevData => ({
      ...prevData,
      regularHours: {
        ...prevData.regularHours,
        [day]: {
          ...prevData.regularHours[day],
          active: !prevData.regularHours[day].active,
        },
      },
    }));
  };

  const isFormChanged = () => {
    return JSON.stringify(formData) !== originalData;
  };

  return (
    <form onSubmit={handleSubmit} className='mb-12'>
      <h2 className='text-[18px] font-semibold mb-2'>Öffnungszeiten</h2>
      <div className='w-full flex gap-[16px]'>
        <div className='flex w-4/6'>
          <div className='w-full flex flex-col gap-[8px]'>
            {Object.keys(formData.regularHours).map((day) => (
              <div key={day} className='flex justify-between h-[40px] w-full'>
                <div className='w-fit flex'>
                  <div className='w-fit h-fit my-auto'>
                    <Switcher
                      isActive={formData.regularHours[day].active}
                      onToggle={() => handleActiveToggle(day)}
                    />
                  </div>
                  <h4 className='my-auto w-[90px] ml-2'>{dayTranslations[day]}</h4>
                </div>
                {formData.regularHours[day].active ? (
                  <>
                    <div className='w-fit flex'>
                      <label className='my-auto w-fit mr-[16px]'>Von</label>
                      <HourTimePick
                        value={formData.regularHours[day].open}
                        onChange={(value) => handleHoursChange(day, 'open', value)}
                      />
                    </div>
                    <div className='w-fit flex'>
                      <label className='my-auto w-fit mr-[16px]'>Bis</label>
                      <HourTimePick
                        value={formData.regularHours[day].close}
                        onChange={(value) => handleHoursChange(day, 'close', value)}
                      />
                    </div>
                  </>
                ) : (
                  <p>Geschlossen</p>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className='hidden flex-col w-2/6'>
          <div>
            <h3>Feiertage:</h3>
            {formData.holidays.map((holiday, index) => (
              <div key={index}>
                <label>Datum:</label>
                <input
                  type="date"
                  name="date"
                  value={holiday.date}
                  onChange={(e) => handleHolidayChange(index, e)}
                />
                <label>Öffnen:</label>
                <input
                  type="time"
                  name="open"
                  value={holiday.open}
                  onChange={(e) => handleHolidayChange(index, e)}
                />
                <label>Schließen:</label>
                <input
                  type="time"
                  name="close"
                  value={holiday.close}
                  onChange={(e) => handleHolidayChange(index, e)}
                />
                <button type="button" onClick={() => removeHoliday(index)}>Entfernen</button>
              </div>
            ))}
            <button type="button" onClick={addHoliday}>Feiertag hinzufügen</button>
          </div>

          <div>
            <h3>Individuelle Tage:</h3>
            {formData.customDates.map((customDate, index) => (
              <div key={index}>
                <label>Datum:</label>
                <input
                  type="date"
                  name="date"
                  value={customDate.date}
                  onChange={(e) => handleCustomDateChange(index, e)}
                />
                <label>Öffnen:</label>
                <input
                  type="time"
                  name="open"
                  value={customDate.open}
                  onChange={(e) => handleCustomDateChange(index, e)}
                />
                <label>Schließen:</label>
                <input
                  type="time"
                  name="close"
                  value={customDate.close}
                  onChange={(e) => handleCustomDateChange(index, e)}
                />
                <button type="button" onClick={() => removeCustomDate(index)}>Entfernen</button>
              </div>
            ))}
            <button type="button" onClick={addCustomDate}>Individuellen Tag hinzufügen</button>
          </div>

          <div>
            <h3>Wochenenden:</h3>
            <label>Samstag Öffnen:</label>
            <input
              type="time"
              value={formData.weekends.saturday.open}
              onChange={(e) => handleChange({ target: { name: 'weekends.saturday.open', value: e.target.value } })}
            />
            <label>Samstag Schließen:</label>
            <input
              type="time"
              value={formData.weekends.saturday.close}
              onChange={(e) => handleChange({ target: { name: 'weekends.saturday.close', value: e.target.value } })}
            />
            <label>Sonntag Öffnen:</label>
            <input
              type="time"
              value={formData.weekends.sunday.open}
              onChange={(e) => handleChange({ target: { name: 'weekends.sunday.open', value: e.target.value } })}
            />
            <label>Sonntag Schließen:</label>
            <input
              type="time"
              value={formData.weekends.sunday.close}
              onChange={(e) => handleChange({ target: { name: 'weekends.sunday.close', value: e.target.value } })}
            />
          </div>
        </div>
      </div>
      {isFormChanged() && <button type="submit">Speichern</button>}
    </form>
  );
};

export default OpeningHoursForm;