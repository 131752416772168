import React from 'react';

function NewBooking() {
    return (
        <div className='flex flex-col justify-start align-middle h-fit w-fit border py-[14px] px-[16px] gap-[16px] border-[#39434C] rounded-[8px]'>
            <div className='flex align-middle'>
                <img src="../assets/components/noticfications/newbooking.png" alt="Neue Buchung Icon" className='flex w-[14px] h-[14px] my-auto mr-[8px]'/>
                <p>Nachricht</p>
            </div>
            <p className='flex w-fit h-auto text-[16px] font-bold'>Neue Buchung <span className='ml-[8px] text-[12px] mt-auto mb-0 font-normal'>Max Mustermann</span></p>
            <p className='text-[12px] ml-0 mr-auto'>vor 1 Stunde</p>
        </div>
    );
}

export default NewBooking;