import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import { useLocation } from "react-router-dom";
import ShowForm from "../forms/ShowForm";

const BookingList = () => {
    const [bookingList, setBookingList] = useState([]);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [selectUser, setSelectUser] = useState(null);
    const location = useLocation();
    const bookingStatusMap = {
        open: "Offen",
        accept: "Bestätigt",
        payed: "Bezahlt",
        live: "Live",
    };
      

    useEffect(() => {
        const fetchBookingList = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axios.get('/bookings', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                setBookingList(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Buchungen:', error);
            }
        };

        fetchBookingList();
    }, []);

    const getFilteredBookings = () => {
        const queryParams = new URLSearchParams(location.search);
        const view = queryParams.get('view');
        
        if (view === 'open') {
            return bookingList.filter(booking => booking.bookingstatus === "open");
        }

        if (view === 'accept') {
            return bookingList.filter(booking => booking.bookingstatus === "accept");
        }

        if (view === 'payed') {
            return bookingList.filter(booking => booking.bookingstatus === "payed");
        }

        if (view === 'live') {
            return bookingList.filter(booking => booking.bookingstatus === "live");
        }
        
        return bookingList;
    };

    const handleAccept = async (bookingId) => {
        try {
            const token = Cookies.get('token');
            await axios.put(`/booking/${bookingId}`, 
                { bookingstatus: "accept" },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            const response = await axios.get('/bookings', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setBookingList(response.data);
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Buchung:', error);
        }
    };

    const handleDelete = async (bookingId) => {
        try {
            const token = Cookies.get('token');
            await axios.delete(`/bookings/${bookingId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            const response = await axios.get('/bookings', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setBookingList(response.data);
        } catch (error) {
            console.error('Fehler beim Löschen der Buchung:', error);
        }
    };

    const filteredBookings = getFilteredBookings();

    const handleRowClick = (bookingId) => {
        setSelectedBookingId(bookingId);
    };

    const openUserDetails = (bookingId) => {
        setSelectUser(bookingId);
    };

    const closeForm = () => {
        setSelectedBookingId(null);
    };

    return (
        <div className="overflow-y-scroll w-[100%] h-[100%]">
            {bookingList.length > 0 ? (
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th className="w-[120px]" style={styles.th}></th>
                            <th style={styles.th}>Profil</th>
                            <th style={styles.th}>Feld</th>
                            <th style={styles.th}>Zeit</th>
                            <th style={styles.th}>Preis</th>
                            <th style={styles.th}>Status</th>
                            <th style={styles.th}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredBookings.map((booking) => (
                        <tr key={booking._id}>
                            <td className="flex flex-col gap-[8px] w-[120px]" style={styles.td}>
                                {booking.bookingstatus === "open" && (
                                    <>
                                        <button className="p-[8px] text-[12px] border rounded-[8px]" onClick={() => handleAccept(booking._id)}>Bestätigen</button>
                                        <button className="p-[8px] text-[12px] border rounded-[8px]" onClick={() => handleDelete(booking._id)}>Löschen</button>
                                    </>
                                )}
                            </td>
                            <td style={styles.td} className="relative" onClick={() => openUserDetails(booking._id)}>
                                <div>{booking.name}</div>
                                <div>{booking.email}</div>
                                {selectUser === booking._id && (
                                    <div className="absolute bottom-0 bg-white p-[8px] border rounded-[8px] min-w-[200px]">
                                        <h5>Profil</h5>
                                        <p>{booking.name}</p>
                                        <p>{booking.name}</p>
                                    </div>
                                )}
                            </td>
                            <td style={styles.td}>{booking.fieldName}</td>
                            <td style={styles.td}>
                                <div>{booking.startTime} - {booking.endTime}</div>
                                <div>
                                    {new Date(booking.bookingDate).toLocaleDateString('de-DE', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                    })}
                                </div>
                            </td>
                            <td style={styles.td}>{booking.price} €</td>
                            <td style={styles.td}>
                            <div className={booking.bookingstatus}></div>
                                {bookingStatusMap[booking.bookingstatus] || "Unbekannt"}
                            </td>
                            <td style={styles.td}>
                                <button onClick={() => handleRowClick(booking._id)}>Details</button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Keine Buchungen gefunden.</p>
            )}

            {selectedBookingId && <ShowForm type="BookingDetails" id={selectedBookingId} onClose={closeForm} />}
        </div>
    );
}

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
  },
  th: {
    backgroundColor: '#f2f2f2',
    padding: '12px',
    border: '1px solid #ddd',
    position: 'sticky',
    top: '-1px',
    left: 0,
    zIndex: 10,
  },
  td: {
    padding: '12px',
    border: '1px solid #ddd',
    minHeight: '60px',
  },
};

export default BookingList;