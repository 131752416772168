import React from "react";

const Website = () => {
  return (
    <>
      <h1>Webseite</h1>
    </>
  );
}

export default Website;