import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import ShowForm from './forms/ShowForm';

const Navigation = ({onLogout}) => {
    const { logout } = useContext(UserContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        onLogout();
        navigate('/login');
    };

    return (
        <nav>
            <div className='flex flex-col h-[100vh] w-[120px] border py-[24px] px-[28px] justify-between'>
                <div className='flex flex-col w-fit h-fit gap-[54px]'>
                    <div className='flex w-[64px] h-[64px] bg-black rounded-[6px]'></div>
                    <ul className='flex flex-col w-fit gap-[24px] mx-auto'>
                        <li>
                            <Link 
                            to="/home" 
                            className={`flex h-[40px] w-[40px] rounded-[10px] ${location.pathname.startsWith('/home') ? 'bg-[#BCBCBC]' : ''}`}
                            >
                            <img src="../assets/navigation/home.png" alt="Icon Home" className={`flex m-auto ${location.pathname.startsWith('/home') ? 'h-[24px] w-[24px]' : 'h-[32px] w-[32px]'}`}/>
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to="/planing" 
                            className={`flex h-[40px] w-[40px] rounded-[10px] ${location.pathname.startsWith('/planing') ? 'bg-[#BCBCBC]' : ''}`}
                            >
                            <img src="../assets/navigation/planing.png" alt="Icon Planing" className={`flex m-auto ${location.pathname.startsWith('/planing') ? 'h-[24px] w-[24px]' : 'h-[32px] w-[32px]'}`}/>
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to="/events" 
                            className={`flex h-[40px] w-[40px] rounded-[10px] ${location.pathname.startsWith('/events') ? 'bg-[#BCBCBC]' : ''}`}
                            >
                            <img src="../assets/navigation/events.png" alt="Icon Events" className={`flex m-auto ${location.pathname.startsWith('/events') ? 'h-[24px] w-[24px]' : 'h-[32px] w-[32px]'}`}/>
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to="/finanz" 
                            className={`flex h-[40px] w-[40px] rounded-[10px] ${location.pathname.startsWith('/finanz') ? 'bg-[#BCBCBC]' : ''}`}
                            >
                            <img src="../assets/navigation/finanz.png" alt="Icon Finanz" className={`flex m-auto ${location.pathname.startsWith('/finanz') ? 'h-[24px] w-[24px]' : 'h-[32px] w-[32px]'}`}/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col w-fit h-fit gap-[24px]'>
                    <button>Einstellungen</button>
                    <button onClick={handleLogout}>Logout</button>
                    <button onClick={<ShowForm />}>
                        <div className='flex w-[64px] h-[64px] bg-black rounded-[50%] relative'>
                            <div className='absolute flex flex-col right-[-24px] w-fit top-[50%] translate-x-[100%] translate-y-[-50%] bg-[#BCBCBC] px-[16px] py-[13px] rounded-[8px] text-start gap-[8px]'>
                                <p className='font-bold'>{user.name}</p>
                                <p>{user.email}</p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;