import React from "react";
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <div className="w-[100%] h-[102px] border py-[24px] px-[28px] flex flex-col justify-between">
            <div className="w-[100%] h-[26px] flex justify-between">
                <h1 className="text-[26px] font-bold">Header</h1>
                <div className="flex my-auto">
                    <button className="flex w-fit h-fit">Search</button>
                </div>
            </div>
            <div>
                <ul className="flex gap-2 text-[14px]">
                    {pathnames.map((value, index) => {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        
                        return (
                        <React.Fragment key={to}>
                            {index > 0 && <li>/</li>}
                            <li>
                            {index === 0 ? (
                                value.charAt(0).toUpperCase() + value.slice(1)
                            ) : (
                                <Link to={to}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                                </Link>
                            )}
                            </li>
                        </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Header;