import React, { useState, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const CourtCreate = ({ onClose }) => {
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [surfaceTypeOptions, setSurfaceTypeOptions] = useState(["Rasen", "Kunstrasen", "Sand"]);
    const [selectedSurfaceTypes, setSelectedSurfaceTypes] = useState([]);
    const [sportTypeOptions, setSportTypeOptions] = useState(["Agility", "Rally Obidience", "IGP", "Sonstiges"]);
    const [selectedSportTypes, setSelectedSportTypes] = useState([]);
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            img.onload = () => {
                if (img.width < 250 || img.height < 250) {
                    setError('Das Bild muss mindestens 250px x 250px groß sein.');
                    setImage(null);
                    setImagePreviewUrl('');
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                } else {
                    const squareSize = Math.min(img.width, img.height);
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    canvas.width = squareSize;
                    canvas.height = squareSize;

                    ctx.drawImage(
                        img,
                        (img.width - squareSize) / 2,
                        (img.height - squareSize) / 2,
                        squareSize,
                        squareSize,
                        0,
                        0,
                        squareSize,
                        squareSize
                    );

                    canvas.toBlob((blob) => {
                        if (blob) {
                            setImage(new File([blob], file.name, { type: file.type }));
                            setImagePreviewUrl(URL.createObjectURL(blob));
                            setError('');
                        }
                    }, file.type);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleImageRemove = () => {
        setImage(null);
        setImagePreviewUrl('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleSurfaceTypeChange = (event) => {
        const selected = event.target.value;
        if (selected && !selectedSurfaceTypes.includes(selected)) {
            setSelectedSurfaceTypes([...selectedSurfaceTypes, selected]);
            setSurfaceTypeOptions(surfaceTypeOptions.filter(option => option !== selected));
        }
    };

    const handleSportTypeChange = (event) => {
        const selected = event.target.value;
        if (selected && !selectedSportTypes.includes(selected)) {
            setSelectedSportTypes([...selectedSportTypes, selected]);
            setSportTypeOptions(sportTypeOptions.filter(option => option !== selected));
        }
    };    

    const removeSurfaceType = (type) => {
        setSelectedSurfaceTypes(selectedSurfaceTypes.filter(selected => selected !== type));
        setSurfaceTypeOptions([...surfaceTypeOptions, type]);
    };

    const removeSportType = (type) => {
        setSelectedSportTypes(selectedSportTypes.filter(selected => selected !== type));
        setSportTypeOptions([...sportTypeOptions, type]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setError('');
    
        if (!width || !length || selectedSurfaceTypes.length === 0 || selectedSportTypes.length === 0) {
            setError('Bitte alle Pflichtfelder ausfüllen.');
            setIsSubmitting(false);
            return;
        }
    
        try {
            const token = Cookies.get('token');
            const formData = new FormData();
            formData.append('width', width);
            formData.append('length', length);
            formData.append('surfaceType', selectedSurfaceTypes.join(','));
            formData.append('sportType', selectedSportTypes.join(','));
            if (image) {
                formData.append('image', image);
            }
    
            console.log("Form Data:", Object.fromEntries(formData.entries()));
    
            await axios.post('/genx/hall/court', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            onClose();
        } catch (error) {
            console.error('Fehler beim Erstellen des Gerichts:', error);
            setError('Beim Erstellen des Gerichts ist ein Fehler aufgetreten.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex flex-col absolute rounded-l-[12px] top-[50%] right-0 translate-y-[-50%] z-[1001] bg-white p-[24px] min-w-[480px] min-h-[80vh] gap-[20px]">
            <div className='flex w-full justify-between'>
                <h2>Neues Feld erstellen</h2>
                <button onClick={onClose}>Schließen</button>
            </div>
            <div className='flex flex-col gap-[8px]'>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col">
                        <label htmlFor="image" className="block mb-2">Bild (optional):</label>
                        {error && <p className="error-message mb-2 text-[12px] text-red-500">{error}</p>}
                        <div className="relative flex items-center justify-center h-[140px] w-[140px] border rounded-[12px] border-gray-300 cursor-pointer mb-[16px]">
                            <input 
                                type="file" 
                                id="image" 
                                accept="image/*" 
                                onChange={handleImageChange} 
                                className="absolute inset-0 opacity-0 cursor-pointer" 
                                ref={fileInputRef}
                            />
                            {imagePreviewUrl ? (
                                <>
                                    <img 
                                        src={imagePreviewUrl} 
                                        alt="Vorschau" 
                                        className="object-cover w-full h-full"
                                    />
                                    <button 
                                        type="button" 
                                        onClick={handleImageRemove} 
                                        className="absolute top-2 right-2 bg-white flex w-[20px] h-[20px] text-[12px] border border-gray-400 p-1 rounded-full text-gray-900"
                                    >
                                        <p className='m-auto'>X</p>
                                    </button>
                                </>
                            ) : (
                                <span>Bild hochladen</span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="width" className="mb-2">Breite (m):</label>
                        <input 
                            type="number" 
                            id="width" 
                            value={width} 
                            onChange={(e) => setWidth(e.target.value)}
                            className="border border-gray-600 rounded-[8px] p-2"
                            placeholder="Breite des Feldes in Metern" 
                            required 
                        />
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="length" className="mb-2">Länge (m):</label>
                        <input 
                            type="number" 
                            id="length" 
                            value={length} 
                            onChange={(e) => setLength(e.target.value)}
                            className="border border-gray-600 rounded-[8px] p-2" 
                            placeholder="Länge des Feldes in Metern" 
                            required 
                        />
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="surfaceType" className="mb-2">Oberflächen-Typ:</label>
                        <select id="surfaceType" className="border border-gray-600 rounded-[8px] p-2" onChange={handleSurfaceTypeChange} value="">
                            <option value="">Wählen Sie eine Oberfläche</option>
                            {surfaceTypeOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        {selectedSurfaceTypes.length > 0 && (
                            <div>
                                {selectedSurfaceTypes.map(type => (
                                    <div key={type} className="flex items-center justify-between mt-1">
                                        <span>{type}</span>
                                        <button 
                                            type="button" 
                                            onClick={() => removeSurfaceType(type)} 
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            Entfernen
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col mb-[16px]">
                        <label htmlFor="sportType" className="mb-2">Sportarten:</label>
                        <select id="sportType" className="border border-gray-600 rounded-[8px] p-2" onChange={handleSportTypeChange} value="">
                            <option value="">Wählen Sie eine Sportart</option>
                            {sportTypeOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        {selectedSportTypes.length > 0 && (
                            <div>
                                {selectedSportTypes.map(type => (
                                    <div key={type} className="flex items-center justify-between mt-1">
                                        <span>{type}</span>
                                        <button 
                                            type="button" 
                                            onClick={() => removeSportType(type)} 
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            Entfernen
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="form-actions flex w-full justify-between">
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Erstelle...' : 'Erstellen'}
                        </button>
                        <button type="button" onClick={onClose}>Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CourtCreate;