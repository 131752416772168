import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Cookies from 'js-cookie';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const { setUserContext } = useContext(UserContext);
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate(`/register`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login', {
        email,
        password,
      });

      const { id, token, userId, email: userEmail, name } = response.data;

      localStorage.setItem('token', token);
      Cookies.set('token', token, { expires: 1 });

      setUserContext(userEmail, userId, name);

      if (onLogin) onLogin();

      navigate('/home/overview');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className='full-container w-[100vw] flex'>
      <div className='w-full md:w-1/2 h-[100vh] flex flex-col content-center p-[24px] justify-between'>
          <div className='flex justify-between'>
              <div className='flex align-middle'><div className='rounded-[50%] h-[40px] w-[40px] bg-gray-400 mr-[8px]'></div><p className='w-fit h-fit my-auto font-bold text-[18px]'>AgiPlus</p></div>
          </div>
          <form onSubmit={handleSubmit} className='h-fit w-fit mx-auto text-center'>
              <h2 className='text-[1.8rem] font-bold'>Login</h2>
              <p>Melde dich bei deinem AgiPlus Account an.</p>
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 w-[360px] px-4 py-3 rounded relative my-2 text-[14px]">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <div className='flex flex-col mt-[16px] max-w-[100%] md:w-[360px] mb-[16px] gap-[8px]'>
                  <div className='flex flex-col text-start'>
                      <label className='mb-[6px] font-semibold'>Email:</label>
                      <input
                      type="email"
                      value={email}
                      className="border border-[#878787] text-[18px] rounded-[8px] py-[8px] px-[12px]"
                      onChange={(e) => setEmail(e.target.value)}
                      />
                  </div>
                  <div className='flex flex-col text-start'>
                      <label className='mb-[6px] font-semibold'>Password:</label>
                      <input
                      type="password"
                      value={password}
                      className="border border-[#878787] text-[18px] rounded-[8px] py-[8px] px-[12px]"
                      onChange={(e) => setPassword(e.target.value)}
                      />
                  </div>
              </div>
              <button type="submit" className="rounded-[8px] mt-[16px] py-[8px] px-[12px] text-[16px] bg-black text-white w-full font-bold">Login</button>
          </form>
          <div className='flex flex-col text-center'>
              <p className='text-[14px]'>Du hast noch keinen Account? Dann Registriere dich.</p>
              <p className="text-blue-500 text-[14px] cursor-pointer" onClick={handleRegisterClick}>Klicke hier</p>
          </div>
      </div>
      <div className='hidden md:flex md:w-1/2 h-100 bg-gray-400'>
      </div>
  </div>
  );
};

export default Login;