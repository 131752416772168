import React from 'react';
import Overview from './booking/Overview';
import { Route, Routes } from 'react-router-dom';
import './Planung.css';
import Calender from './booking/Calender';
import Trainer from './coaches/Trainer';

const Planung = () => {
    return (
        <>
            <div className='w-full flex h-[100%]'>
                <Routes>
                    <Route path="/overview/calender" element={<Calender />} />
                    <Route path="/booking" element={<Overview />} />
                    <Route path="/coaches" element={<Trainer />} />
                </Routes>
            </div>
        </>
    );
};

export default Planung;