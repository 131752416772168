import React from "react";
import { Route, Routes } from "react-router-dom";

const Overview = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<p>Hi</p>} />
                <Route path="/calender" element={<p>Hi</p>} />
            </Routes>
        </div>
    );
}

export default Overview;