import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShowForm from "../forms/ShowForm";

const BookingOverviewFilter = () => {
    const [createBooking, setCreateBooking] = useState(false);

    const openCreateBookingForm = () => {
        setCreateBooking(true);
    }

    const closeForm = () => {
        setCreateBooking(false);
    }
    
    return (
        <div className="flex justify-between mb-[12px]">
            <div className="flex gap-[24px]">
                <Link to="?view=all" className="text-[#000] font-bold text-[16px] p-[12px]">Alle</Link>
                <Link to="?view=open" className="text-[#000] font-bold text-[16px] p-[12px]">Offen</Link>
                <Link to="?view=accept" className="text-[#000] font-bold text-[16px] p-[12px]">Bestätigt</Link>
                <Link to="?view=payed" className="text-[#000] font-bold text-[16px] p-[12px]">Bezahlt</Link>
                <Link to="?view=live" className="text-[#000] font-bold text-[16px] p-[12px]">Live</Link>
            </div>
            <div>
                <button className="p-[12px] font-[14px] border" onClick={() => openCreateBookingForm()}>Erstellen</button>
            </div>
            {createBooking && <ShowForm type="CreateBooking" onClose={closeForm} />}
        </div>
    );
}

export default BookingOverviewFilter;