import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Overview from './events/Overview';

const Events = () => {
    return (
        <>
            <Routes>
                <Route path="/overview*" element={<Overview />} />
            </Routes>
        </>
    );
};

export default Events;